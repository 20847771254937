import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { MenuSurvey, Ads, Endpoints, Users } from 'assets/svg';
// import { Integration } from 'assets/svg';
import { StyledSider, MenuIcon } from './styles';

const projectMenu = [
  { name: 'surveys', icon: <MenuSurvey /> },
  { name: 'endpoints', icon: <Endpoints /> },
  { name: 'ads', icon: <Ads /> },
  { name: 'users', icon: <Users /> },
  // { name: 'integrations', icon: <Integration /> },
];

export default function SiderMenu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const locationKey = pathname.split('/')[2];

  const handleMenuClick = (key) => {
    history.push(`/project/${key}`);
  };

  const toggleCollapsedState = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <StyledSider collapsed={collapsed} flex="0 0 200px">
      <MenuIcon onClick={toggleCollapsedState} />
      <Menu mode="inline" selectedKeys={[locationKey]}>
        {projectMenu.map(({ name, icon }) => (
          <Menu.Item
            key={name}
            icon={icon}
            onClick={() => handleMenuClick(name)}
          >
            {t(name)}
          </Menu.Item>
        ))}
      </Menu>
    </StyledSider>
  );
}
