import styled from 'styled-components';
import { Button, Form, Input, Menu, Skeleton, Typography } from 'antd';
const { Title } = Typography;

export const LayoutWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 420px;
`;

export const TitleCenter = styled(Title)`
  text-align: center;
  && {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-top: 24px;
  margin-bottom: 0;
  label {
    color: ${({ theme }) => theme.lightGray};
  }
`;

export const MarginButton = styled(Button)`
  margin: 32px 0;
`;

export const StyledMenu = styled(Menu)`
  min-width: 140px;
  padding: 12px 0;
  border-radius: 10px;

  li {
    align-items: center;
    padding: 12px 16px;

    &:first-child {
      color: #7d80a1;
    }

    &:hover {
      background: #f1f4f9;
    }
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  padding: 12px 16px;

  &.ant-dropdown-menu-item-selected {
    color: #180c2c;
    font-weight: 600;
    background: #f9faff;
  }
  svg {
    margin-right: 8px;
  }

  &:hover {
    background: #f9faff;
  }
`;

export const ModalFooter = styled.div`
  text-align: right;
  margin-top: 20px
`;

export const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  h4 {
    margin-bottom: 0;
  }
`;

export const StyledSearch = styled(Input)`
  width: 300px;
  font-size: 14px;
  margin-bottom: 32px;
`;

export const MinWidthButton = styled(Button)`
  min-width: 116px;
  margin-top: 8px;
`;

export const CustomSkeleton = styled(Skeleton)`
  &&& {
    h3 {
      height: 40px;
      border-radius: 12px;
    }
    ul {
      li {
        height: 60px;
        border-radius: 12px;
      }
    }
  }
`;

export const StyledBackBtn = styled.div`
  cursor: pointer;
  color: #180c2c;
  font-weight: 500;
  svg {
    margin-right: 10px;
  }
`;
