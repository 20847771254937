export const CREATE_WORKSPACE_REQUEST = 'CREATE_WORKSPACE_REQUEST';
export const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS';
export const SET_HAS_REQUESTED_FALSE = 'SET_HAS_REQUESTED_FALSE';
export const PROJECTS_LIST_REQUEST = 'PROJECTS_LIST_REQUEST';
export const PROJECTS_LIST_SUCCESS = 'PROJECTS_LIST_SUCCESS';
export const INVITATIONS_LIST_REQUEST = 'INVITATIONS_LIST_REQUEST';
export const INVITATIONS_LIST_SUCCESS = 'INVITATIONS_LIST_SUCCESS';
export const PROJECT_EDIT_REQUEST = 'PROJECT_EDIT_REQUEST';
export const PROJECT_EDIT_SUCCESS = 'PROJECT_EDIT_SUCCESS';
export const PROJECT_DELETE_REQUEST = 'PROJECT_DELETE_REQUEST';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const ACCEPT_OR_REJECT_INVITATION_REQUEST =
  'ACCEPT_OR_REJECT_INVITATION_REQUEST';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const REJECT_INVITATION_SUCCESS = 'REJECT_INVITATION_SUCCESS';
export const CURRENT_PROJECT_ID = 'CURRENT_PROJECT_ID';
export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';
