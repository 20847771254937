import axios from 'axios';
import i18n from 'i18n';

import { getItemFromLocalStorage, setItemToLocalStorage } from 'utils/helpers';
import { SET_ERROR } from 'shared/error/constants';
import { store } from 'store/configure-store';
import { setFetchingStatus } from 'store/sharedActions';

import { BASE_URL } from './endpoints';

let authTokenRequest;

const dispatchAction = (action) => {
  const { dispatch } = store;
  dispatch(action);
};

export function* handleRequestFail(error) {
  dispatchAction({ type: SET_ERROR, error });
}

export default function* sendRequest({
  method = 'GET',
  endpoint,
  data = null,
  headers,
  options = null,
}) {
  const response = yield instance({
    method,
    url: endpoint,
    data,
    headers,
    options,
  });

  return response || {};
}

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    if (config?.options?.loader !== false) {
      dispatchAction(setFetchingStatus(true));
    }
    const token = getItemFromLocalStorage('token');
    config = {
      ...config,
      headers: {
        projectid: getItemFromLocalStorage('projectId'),
      },
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    dispatchAction({ type: SET_ERROR, error });
    dispatchAction(setFetchingStatus(false));
    Promise.reject(error);
  }
);

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = instance('/account/tokenRefresh', {
      method: 'POST',
      data: {
        token: getItemFromLocalStorage('token'),
        refreshToken: getItemFromLocalStorage('refreshToken'),
      },
    });
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

instance.interceptors.response.use(
  (res) => {
    dispatchAction(setFetchingStatus(false));
    return res;
  },
  (error) => {
    dispatchAction(setFetchingStatus(false));
    if (error.response) {
      const { status, config } = error.response;
      const originalRequest = config;

      if (status === 401 && !originalRequest.isRetry) {
        if (originalRequest.url.includes('tokenRefresh')) {
          dispatchAction({
            type: SET_ERROR,
            error: {
              ...error,
              response: {
                ...error.response,
                data: i18n.t('session-expired'),
              },
            },
          });
        }
        return getAuthToken()
          .then((res) => {
            const { Token, RefreshToken } = res.data;

            originalRequest.isRetry = true;
            originalRequest.headers.Authorization = `Bearer ${Token}`;

            setItemToLocalStorage('token', Token);
            setItemToLocalStorage('refreshToken', RefreshToken);

            return instance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }
    }
    return Promise.reject(error);
  }
);
