import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Image, Dropdown, Menu } from 'antd';
import { getProjectsList, getProjectId } from 'store/selectors';
import {
  setItemToLocalStorage,
  getItemFromLocalStorage,
  hasSelectedProject,
} from 'utils/helpers';
import { ReactComponent as Back } from 'assets/svg/back.svg';
import { ReactComponent as DownArrow } from 'assets/svg/down-arrow.svg';
import { Eye, Logo } from 'assets/svg';
import { logout } from 'pages/auth/actions';
import { getProjects } from 'pages/workspaces/actions';
import { StyledMenu } from 'shared/styles';
import {
  HeaderWrapper,
  FlexJustified,
  UserInfo,
  ImageDiv,
  StyledProjectsListBtn,
  BackButton,
  PreviewBtn,
  PublishBtn,
} from './styles';

const MenuItem = Menu.Item;

export default function PageHeader() {
  const user = JSON.parse(getItemFromLocalStorage('user'));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectsList = useSelector(getProjectsList);
  const projectId = useSelector(getProjectId);
  const history = useHistory();

  const { pathname } = history.location;
  const firstName = user?.FirstName;
  const lastName = user?.LastName;
  const imgAlt =
    firstName && lastName && firstName[0] && lastName[0]
      ? `${firstName[0]} ${lastName[0]}`
      : '';

  useEffect(() => {
    dispatch({
      type: 'CURRENT_PROJECT_ID',
    });
  });

  const handleLogOut = () => {
    dispatch(logout(true));
  };

  const handleChangeProject = ({ key }) => {
    // insertProjectInHeader(key);
    setItemToLocalStorage('projectId', key);
    dispatch({
      type: 'SET_CURRENT_PROJECT_ID',
      payload: { key },
    });
  };

  const workspaces = (
    <StyledMenu>
      <MenuItem key={0} onClick={() => history.push('/workspaces')}>
        <Back /> {t('back-to-all')}
      </MenuItem>
      {projectsList.map(({ Id, Name }) => (
        <MenuItem key={Id} onClick={handleChangeProject}>
          {Id === projectId ? <b>{Name}</b> : Name}
        </MenuItem>
      ))}
    </StyledMenu>
  );

  const renderProjects = () =>
    pathname.includes('project') ? (
      <div>
        <Dropdown overlay={workspaces} trigger={['click']}>
          <StyledProjectsListBtn type="link">
            {
              // eslint-disable-next-line eqeqeq
              projectsList.find(({ Id }) => Id == projectId)?.Name
            }
            <DownArrow />
          </StyledProjectsListBtn>
        </Dropdown>
      </div>
    ) : null;

  const action = (
    <StyledMenu>
      <MenuItem key="profile" onClick={() => history.push('/profile')}>
        {t('my-profile')}
      </MenuItem>
      <MenuItem key="logOut" onClick={handleLogOut}>
        {t('log-out')}
      </MenuItem>
    </StyledMenu>
  );

  useEffect(() => {
    if (!history.location.pathname.includes('workspaces')) {
      dispatch(getProjects());
    }
  }, [dispatch, history]);

  return (
    <HeaderWrapper>
      <FlexJustified>
        {!history.location.pathname.includes('surveys/pages') ? (
          <>
            <Logo />
            {hasSelectedProject() ? renderProjects() : null}
          </>
        ) : (
          <BackButton to="/project/surveys">
            <Back /> {t('back-to-survey-list')}
          </BackButton>
        )}
      </FlexJustified>

      <FlexJustified>
        {!history.location.pathname.includes('surveys/pages') ? (
          <UserInfo>
            {firstName} {lastName}
          </UserInfo>
        ) : (
          <>
            <PreviewBtn type="text">
              <Eye /> {t('preview')}
            </PreviewBtn>
            <PublishBtn type="primary">{t('publish')}</PublishBtn>
          </>
        )}

        <Dropdown overlay={action} placement="bottomRight" trigger="click">
          <ImageDiv>
            {user?.Image ? <Image alt={imgAlt} preview={false} /> : imgAlt}
          </ImageDiv>
        </Dropdown>
      </FlexJustified>
    </HeaderWrapper>
  );
}
