import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import * as types from './constants';

function* getIntegrations() {
  const data = {
    method: 'GET',
    endpoint: ep.PROJECT_INTEGRATION,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.INTEGRATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.INTEGRATIONS_REQUEST);
  }
}

function* integrate({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.PROJECT_INTEGRATION,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.INTEGRATE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.INTEGRATE_REQUEST, { payload });
  }
}

export default function* usersRequestWatcher() {
  yield takeLatest(types.INTEGRATIONS_REQUEST, getIntegrations);
  yield takeLatest(types.INTEGRATE_REQUEST, integrate);
}
