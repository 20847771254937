import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import { setItemToLocalStorage, stringify } from 'utils/helpers';
import * as ep from 'api/endpoints';
import * as types from './constants';

function* changeBio({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: ep.CHANGE_BIO,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    setItemToLocalStorage('user', stringify(payload));

    yield put({ type: types.USER_BIO_SUCCESS });
  } catch (e) {
    yield handleRequestFail(e, types.USER_BIO_REQUEST, { payload });
  }
}

function* changePass({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/changePassword`,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    yield put({ type: types.USER_PASS_SUCCESS });
  } catch (e) {
    yield handleRequestFail(e, types.USER_PASS_REQUEST, { payload });
  }
}

export default function* profileRequestWatcher() {
  yield takeLatest(types.USER_BIO_REQUEST, changeBio);
  yield takeLatest(types.USER_PASS_REQUEST, changePass);
}
