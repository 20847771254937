import * as types from './constants';

const initialState = {
  hasRequested: false,
  projectsList: [],
  invitationsList: [],
  currentProjectId: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        projectsList: payload,
      };
    case types.INVITATIONS_LIST_SUCCESS:
      return {
        ...state,
        invitationsList: payload,
      };
    case types.CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        projectsList: [payload, ...state.projectsList],
        hasRequested: true,
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    case types.PROJECT_EDIT_SUCCESS:
      return {
        ...state,
        hasRequested: true,
        projectsList: state.projectsList.map((item) => {
          if (item.Id === payload.Id) {
            return {
              ...item,
              Name: payload.Name,
            };
          }

          return item;
        }),
      };
    case types.PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        hasRequested: true,
        projectsList: state.projectsList.filter(({ Id }) => Id !== payload.Id),
      };
    case types.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        projectsList: [payload, ...state.projectsList],
        invitationsList: state.invitationsList.filter(
          ({ Id }) => Id !== payload.id
        ),
        hasRequested: true,
      };
    case types.REJECT_INVITATION_SUCCESS:
      return {
        ...state,
        hasRequested: true,
        invitationsList: state.invitationsList.filter(
          ({ Id }) => Id !== payload.id
        ),
      };
    case types.CURRENT_PROJECT_ID:
      return {
        ...state,
        currentProjectId: localStorage.getItem('projectId'),
      };
    case types.SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        currentProjectId: payload.key,
      };
    default:
      return state;
  }
};
