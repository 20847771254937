import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import { downloadResponse } from 'utils/helpers';
import * as types from './constants';

function* getSurveys() {
  const data = {
    method: 'GET',
    endpoint: ep.SURVEY,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SURVEYS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SURVEYS_LIST_REQUEST);
  }
}

function* addSurvey({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.SURVEY,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.ADD_SURVEY_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ADD_SURVEY_REQUEST, {
      payload,
    });
  }
}

function* getEligibles({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/getEligibles?surveyId=${payload}`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.ELIGIBLES_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ELIGIBLES_LIST_REQUEST, {
      payload,
    });
  }
}

function* launchSurvey({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ENDPOINTS}/ApplyToEndpoints`,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.LAUNCH_SURVEY_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.LAUNCH_SURVEY_REQUEST, {
      payload,
    });
  }
}

function* renameSurvey({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.SURVEY,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.RENAME_SURVEY_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.RENAME_SURVEY_REQUEST, {
      payload,
    });
  }
}

function* deleteSurvey({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.SURVEY}/${payload}`,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.DELETE_SURVEY_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DELETE_SURVEY_REQUEST, {
      payload,
    });
  }
}

function* duplicateSurvey({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.SURVEY}/Duplicate?surveyId=${payload.surveyId}`,
  };

  try {
    const response = yield call(sendRequest, data);
    const date = new Date();

    yield put({
      type: types.DUPLICATE_SURVEY_SUCCESS,
      payload: {
        Id: response.data.Id,
        Name: response.data.Name,
        EndpointCount: 0,
        CreatedDate: date.toUTCString(),
        Email: JSON.parse(localStorage.getItem('user'))?.Email,
        ProjectId: localStorage.getItem('projectId'),
        StatusId: payload.StatusId,
      },
    });
  } catch (e) {
    yield handleRequestFail(e, types.DUPLICATE_SURVEY_REQUEST, {
      payload,
    });
  }
}

function* getSummary({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.REPORT}/Summary`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.SUMMARY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SUMMARY_REPORT_REQUEST, {
      payload,
    });
  }
}

function* getEndpointsUsedForSurvey({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/GetUsedForSurvey?surveyId=${payload.surveyId}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.ENDPOINTS_USED_FOR_SURVEY_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ENDPOINTS_USED_FOR_SURVEY_REQUEST, {
      payload,
    });
  }
}

function* exportReport({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.REPORT}/RowData`,
    data: payload.data,
  };

  try {
    const response = yield call(sendRequest, data);
    if (response.status === 200) {
      downloadResponse(
        `data:image/png;base64,${response.data}`,
        `${payload.survey}${
          payload.data.startDate ? `_${payload.data.startDate}` : ''
        }${payload.data.endDate ? `-${payload.data.endDate}` : ''}.xlsx`
      );
    }
  } catch (e) {
    yield handleRequestFail(e, types.EXPORT_REPORT_REQUEST, {
      payload,
    });
  }
}

export default function* usersRequestWatcher() {
  yield takeLatest(types.SURVEYS_LIST_REQUEST, getSurveys);
  yield takeLatest(types.ADD_SURVEY_REQUEST, addSurvey);
  yield takeLatest(types.ELIGIBLES_LIST_REQUEST, getEligibles);
  yield takeLatest(types.LAUNCH_SURVEY_REQUEST, launchSurvey);
  yield takeLatest(types.RENAME_SURVEY_REQUEST, renameSurvey);
  yield takeLatest(types.DELETE_SURVEY_REQUEST, deleteSurvey);
  yield takeLatest(types.DUPLICATE_SURVEY_REQUEST, duplicateSurvey);
  yield takeLatest(types.SUMMARY_REPORT_REQUEST, getSummary);
  yield takeLatest(
    types.ENDPOINTS_USED_FOR_SURVEY_REQUEST,
    getEndpointsUsedForSurvey
  );
  yield takeLatest(types.EXPORT_REPORT_REQUEST, exportReport);
}
