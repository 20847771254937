import * as types from './constants';

export const addWorkspace = (payload) => ({
  type: types.CREATE_WORKSPACE_REQUEST,
  payload,
});

export const setHasRequestedFalse = () => ({
  type: types.SET_HAS_REQUESTED_FALSE,
});

export const getProjects = () => ({
  type: types.PROJECTS_LIST_REQUEST,
});

export const getInvitations = () => ({
  type: types.INVITATIONS_LIST_REQUEST,
});

export const editProject = (payload) => ({
  type: types.PROJECT_EDIT_REQUEST,
  payload,
});

export const deleteProject = (payload) => ({
  type: types.PROJECT_DELETE_REQUEST,
  payload,
});

export const acceptOrRejectInvitation = (payload) => ({
  type: types.ACCEPT_OR_REJECT_INVITATION_REQUEST,
  payload,
});
