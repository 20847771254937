import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import sendRequest from 'api/axios';
import * as ep from 'api/endpoints';

import {
  setupAppInfo,
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from 'utils/helpers';
import { SET_ERROR } from 'shared/error/constants';
import * as types from './constants';

function* register({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/register`,
    data: payload,
  };
  try {
    yield call(sendRequest, data);

    yield put(push('/verifyEmail'));
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

function* forgotPass({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/forgotPassword`,

    data: payload,
  };
  try {
    yield call(sendRequest, data);

    yield put({
      type: types.FORGOT_PASS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

function* changePass({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/changePassword`,
    data: payload,
  };
  try {
    yield call(sendRequest, data);

    yield put(push('/login'));
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

function* login({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/login`,
    data: payload,
  };
  try {
    const response = yield call(sendRequest, data);

    setupAppInfo(response.data);

    yield put(push('/workspaces'));
  } catch (error) {
    if (error.response.status === 401) {
      yield put({
        type: types.LOGIN_USER_FAILED,
      });
    } else {
      yield put({ type: SET_ERROR, error });
    }
  }
}

function* logout({ payload: doRequest }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/logOut`,
  };
  try {
    if (doRequest) {
      yield call(sendRequest, data);
    }

    const i18nextLng = getItemFromLocalStorage('i18nextLng');
    localStorage.clear();
    setItemToLocalStorage('i18nextLng', i18nextLng);

    yield put(push('/login'));
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

function* confirmEmailData({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/confirmRegistration`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);
    setupAppInfo(response.data);
    yield put(push('/workspaces'));
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

function* checkTokenValid({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ACCOUNT}/IsTokenValid`,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    setItemToLocalStorage('email', payload.Email);
    setItemToLocalStorage('resetToken', payload.Token);

    yield put(push('/resetPassword'));
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

function* resetPassword({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.ACCOUNT}/ResetPassword`,
    data: payload,
  };

  try {
    yield call(sendRequest, data);
    localStorage.removeItem('resetToken');
    localStorage.removeItem('email');
    yield put(push('/login'));
  } catch (error) {
    yield put({ type: SET_ERROR, error });
  }
}

export default function* loginRequestWatcher() {
  yield takeLatest(types.REGISTER_USER_REQUEST, register);
  yield takeLatest(types.FORGOT_PASS_REQUEST, forgotPass);
  yield takeLatest(types.CHANGE_PASS_REQUEST, changePass);
  yield takeLatest(types.LOGIN_USER_REQUEST, login);
  yield takeLatest(types.LOGOUT_USER, logout);
  yield takeLatest(types.CONFIRM_EMAIL_REQUEST, confirmEmailData);
  yield takeLatest(types.IS_TOKEN_VALID_REQUEST, checkTokenValid);
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword);
}
