import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import login from 'pages/auth/reducers';
import profile from 'pages/profile/reducers';
import workspaces from 'pages/workspaces/reducers';
import users from 'pages/project/users/reducers';
import endpoints from 'pages/project/endpoints/reducers';
import integrations from 'pages/project/integrations/reducers';
import surveys from 'pages/project/surveys/reducers';
import pages from 'pages/project/surveys/pages/reducer';
import ads from 'pages/project/ads/reducers';
import surveyUI from 'pages/surveyUI/reducers';
import error from './errorReducer';
import shared from './sharedReducer';

const rootReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user: login,
    profile,
    workspaces,
    users,
    endpoints,
    integrations,
    surveys,
    ads,
    pages,
    surveyUI,
    error,
    shared,
  });

  return (state, action) => appReducer(state, action);
};

export default rootReducer;
