import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

import { isAuthenticated } from 'utils/helpers';
import Loader from 'layout/loader';
import { getLoading } from 'store/selectors';
import { LeftSider, StyledLogo, StyledBG } from './styles';

const PublicRoute = ({ component: Component, ...rest }) => {
  const isFetching = useSelector(getLoading);

  return (
    <Route {...rest}>
      {isAuthenticated() ? (
        <Redirect to="/workspaces" />
      ) : (
        <Layout className="main-app-section" style={{ minHeight: '100vh' }}>
          {isFetching ? <Loader /> : null}
          <LeftSider width={560} breakpoint="lg" collapsedWidth={300}>
            <StyledLogo />
            <StyledBG />
          </LeftSider>
          <Layout style={{ background: '#FBFCFF' }}>
            <Component />
          </Layout>
        </Layout>
      )}
    </Route>
  );
};

export default PublicRoute;
