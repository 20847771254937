import * as types from './constants';

const initialState = {
  ui: {},
  nextQuestion: null,
  status: {
    startSurvey: false,
    endSurvey: false,
  },
  showInfoMsg: false,
  qrType: null,
  refreshSession: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.REFRESH_SESSION:
      return { ...state, refreshSession: payload };
    case types.UI_SUCCESS:
      return { ...state, ui: payload };
    case types.QRTYPE_SUCCESS:
      return {...state, qrType: payload};
    case types.START_SURVEY:
      return {
        ...state,
        status: { ...state.status, startSurvey: true, endSurvey: false },
      };
    case types.ANSWER_QUESTION_SUCCESS:
      return { ...state, nextQuestion: payload };
    case types.QUESTION_SUCCESS:
      return {
        ...state,
        nextQuestion: payload,
        status: { ...state.status, startSurvey: true, endSurvey: false },
      };
    case types.END_SURVEY:
      return {
        ...state,
        nextQuestion: null,
        status: { ...state.status, startSurvey: false, endSurvey: true },
      };
    case types.SHOW_MSG_INFO:
      return {
        ...state,
        showInfoMsg: payload,
      };

    default:
      return state;
  }
};
