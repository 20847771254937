import * as types from './constants';

const initialState = {
  surveysList: [],
  eligiblesList: [],
  hasRequested: false,
  summaryReport: {},
  usedEndpoints: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SURVEYS_LIST_SUCCESS:
      return {
        ...state,
        surveysList: payload,
      };
    case types.ADD_SURVEY_SUCCESS:
      return {
        ...state,
        surveysList: [payload, ...state.surveysList],
        hasRequested: true,
      };
    case types.RENAME_SURVEY_SUCCESS:
      return {
        ...state,
        surveysList: state.surveysList.map((item) => {
          if (item.Id === payload.Id) {
            return {
              ...item,
              Name: payload.Name,
            };
          }
          return item;
        }),
        hasRequested: true,
      };
    case types.ELIGIBLES_LIST_SUCCESS:
      return {
        ...state,
        eligiblesList: payload,
      };
    case types.LAUNCH_SURVEY_SUCCESS:
      return {
        ...state,
        surveysList: state.surveysList.map((item) => {
          if (item.Id === payload.SurveyId) {
            return {
              ...item,
              StatusId: payload.EndpointIds?.length ? 2 : 1,
            };
          }

          return item;
        }),
        hasRequested: true,
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    case types.DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        surveysList: state.surveysList.filter(({ Id }) => Id !== payload),
        hasRequested: true,
      };
    case types.DUPLICATE_SURVEY_SUCCESS:
      return {
        ...state,
        surveysList: [...state.surveysList, payload],
      };
    case types.SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        summaryReport: payload,
      };
    case types.ENDPOINTS_USED_FOR_SURVEY_SUCCESS:
      return {
        ...state,
        usedEndpoints: payload,
      };
    default:
      return state;
  }
};
