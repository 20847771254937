import * as types from './constants';

const initialState = {
  design: [],
  questions: [],
  createdQuestions: [],
  editableQuestion: {},
  eligables: [],
  componentDetails: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.COMPONENT_DETAILS_SUCCESS:
      return {
        ...state,
        componentDetails: payload
      }   
    case types.QUESTIONS_TYPES_SUCCESS:
      return {
        ...state,
        questions: payload,
        editableQuestion: {},
      };
    case types.DESIGN_SUCCESS:
      return {
        ...state,
        design: payload,
      };
    case types.CREATE_PAGE_SUCCESS:
      return {
        ...state,
        createdQuestions: [
          ...state.createdQuestions.map((question) => ({
            ...question,
          })),
          payload,
        ],
        editableQuestion: {},
      };
    case types.SURVEY_PAGES_SUCCESS:
    case types.SURVEY_PAGES_REORDER_SUCCESS:
      return {
        ...state,
        createdQuestions: payload ?? [],
      };
    case types.PAGE_SUCCESS:
      return {
        ...state,
        editableQuestion: { ...payload },
      };
    case types.DELETE_PAGE_SUCCESS:
      return {
        ...state,
        createdQuestions: state.createdQuestions.filter(
          ({ Id }) => Id !== payload
        ),
        editableQuestion: {},
      };
    case types.BTN_UPDATE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          Buttons: [{ ...state.editableQuestion.Buttons[0], ...payload }],
        },
      };
    case types.FORM_ITEM_UPDATE_SUCCESS:{
      const ind = state.editableQuestion.QuestionComponents.findIndex(item => item.LabelId === payload.QuestionComponents[0].LabelId);
      if(ind !== -1){
        const newComponents=[...state.editableQuestion.QuestionComponents]
        newComponents[ind]={...newComponents[ind],...payload.QuestionComponents[0]}
  
        return {
          ...state,
          editableQuestion: {
            ...state.editableQuestion,
            QuestionComponents: newComponents
          }
        };
      }
      return state
      
    }
    case types.MULTICOLUMN_UPDATE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          IsMultiColumn: payload.IsMultiColumn,
        },
      };
    case types.LABEL_UPDATE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          ScoreLabels: payload.ScoreLabels,
        },
      };
    case types.QUESTION_UPDATE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          ...payload,
        },
      };
    case types.MULTI_CHOICE_UPDATE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          ...payload,
        },
      };
    case types.NEW_OPTION_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          Answers: [
            ...state.editableQuestion.Answers,
            {
              Id: payload.Id,
              AnswerText: payload.AnswerText,
              Order: payload.Order,
            },
          ],
        },
      };
    case types.ANSWER_UPDATE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          Answers: state.editableQuestion?.Answers.map((answer) => {
            if (answer.Id === Number(payload.Answers[0].Id)) {
              return {
                ...answer,
                AnswerText: payload.Answers[0].AnswerText,
              };
            }

            return answer;
          }),
        },
      };
    case types.ANSWER_DELETE_SUCCESS:
      return {
        ...state,
        editableQuestion: {
          ...state.editableQuestion,
          Answers: state.editableQuestion.Answers.filter(
            ({ Id }) => payload.Id !== Id
          ),
        },
      };
    case types.CHANGE_TYPE_SUCCESS:
      return {
        ...state,
        editableQuestion: {},
        createdQuestions: state.createdQuestions.map((item) => {
          if (item.Id === payload.data.Id) {
            return {
              ...item,
              QuestionText: payload.data.QuestionText,
              Key: payload.key,
            };
          }

          return item;
        }),
      };
    case types.DUPLICATE_QUESTION_SUCCESS:
      return {
        ...state,
        createdQuestions: [
          ...state.createdQuestions,
          {
            ...payload.question,
            Id: payload.Id,
          },
        ],
      };
    case types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        design: {
          ...state.design,
          [payload.tab]: {
            ...state.design[payload.tab],
            BackgroundImage: payload.backgroundImage,
            Id: payload.id,
          },
        },
      };
    case types.TAB_IMG_REMOVE_SUCCESS:
      return {
        ...state,
        design: {
          ...state.design,
          [payload]: {
            ...state.design[payload],
            BackgroundImage: null,
          },
        },
      };
    case types.PAGE_DESIGN_SUCCESS:
      return {
        ...state,
        design: {
          ...state.design,
          ...payload,
        },
      };
    case types.ELIGABLE_QUESTIONS_SUCCESS:
      return {
        ...state,
        eligables: payload,
      };
    case types.SAVE_NEXT_QUESTION_SUCCESS:
      return {
        ...state,
        createdQuestions: state.createdQuestions.map((question) => {
          if (question.Id === payload.Id) {
            return payload;
          }
          return question;
        }),
      };
    default:
      return state;
  }
};
