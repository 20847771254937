import * as types from './constants';

const initialState = {
  loginFailed: false,
  forgotPassRequested: false,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case types.AUTH_INITIALIZE:
      return initialState;
    case types.LOGIN_USER_REQUEST:
      return {
        ...state,
        loginFailed: false,
      };
    case types.LOGIN_USER_FAILED:
      return {
        ...state,
        loginFailed: true,
      };
    case types.FORGOT_PASS_SUCCESS:
      return {
        ...state,
        forgotPassRequested: true,
      };
    default:
      return state;
  }
};
