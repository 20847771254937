import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import * as types from './constants';

function* addWorkspace({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.PROJECT,
    data: payload,
    headers: { projectid: null },
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.CREATE_WORKSPACE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.CREATE_WORKSPACE_REQUEST, {
      payload,
    });
  }
}

function* getProjects() {
  const data = {
    method: 'GET',
    endpoint: ep.PROJECT,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.PROJECTS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.PROJECTS_LIST_REQUEST);
  }
}

function* getInvitations() {
  const data = {
    method: 'GET',
    endpoint: `${ep.INVITATION}/userInvitations`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.INVITATIONS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.INVITATIONS_LIST_REQUEST);
  }
}

function* editProject({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: ep.PROJECT,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.PROJECT_EDIT_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.PROJECT_EDIT_REQUEST, { payload });
  }
}

function* deleteProject({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: ep.PROJECT,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.PROJECT_DELETE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.PROJECT_DELETE_REQUEST, { payload });
  }
}

function* acceptOrRejectInvitation({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.INVITATION}/Respond`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    if (payload.Status === 1) {
      yield put({
        type: types.ACCEPT_INVITATION_SUCCESS,
        payload: {
          ...response.data,
          id: payload.Id,
        },
      });
    } else if (payload.Status === 2) {
      yield put({
        type: types.REJECT_INVITATION_SUCCESS,
        payload: {
          ...response.data,
          id: payload.Id,
        },
      });
    }
  } catch (e) {
    yield handleRequestFail(e, types.ACCEPT_OR_REJECT_INVITATION_REQUEST, {
      payload,
    });
  }
}

export default function* workspacesRequestWatcher() {
  yield takeLatest(types.CREATE_WORKSPACE_REQUEST, addWorkspace);
  yield takeLatest(types.PROJECTS_LIST_REQUEST, getProjects);
  yield takeLatest(types.INVITATIONS_LIST_REQUEST, getInvitations);
  yield takeLatest(types.PROJECT_EDIT_REQUEST, editProject);
  yield takeLatest(types.PROJECT_DELETE_REQUEST, deleteProject);
  yield takeLatest(
    types.ACCEPT_OR_REJECT_INVITATION_REQUEST,
    acceptOrRejectInvitation
  );
}
