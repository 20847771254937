import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { getIsOpen, getMessage } from 'store/selectors';
import { logout } from 'pages/auth/actions';
import { Error } from 'assets/svg';
import { HIDE_ERROR } from './constants';
import { ErrorModal, StyledButton } from './styles';
const { Title } = Typography;

const ErrorNotification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOpen = useSelector(getIsOpen);
  const message = useSelector(getMessage);

  const handleClose = () => {
    dispatch({ type: HIDE_ERROR });
    if (message === 'Session Expired') {
      dispatch(logout());
    }
  };

  return (
    <ErrorModal
      centered
      visible={isOpen}
      closable={false}
      footer={false}
      bodyStyle={{ padding: '32px' }}
      zIndex={1001}
    >
      <Error />
      <Title level={4}> {message.Message || message}</Title>

      <StyledButton size="large" type="primary" onClick={handleClose}>
        {t('ok')}
      </StyledButton>
    </ErrorModal>
  );
};

export default ErrorNotification;
