import {
  REGISTER_USER_REQUEST,
  LOGIN_USER_REQUEST,
  LOGOUT_USER,
  FORGOT_PASS_REQUEST,
  AUTH_INITIALIZE,
  CHANGE_PASS_REQUEST,
  CONFIRM_EMAIL_REQUEST,
  IS_TOKEN_VALID_REQUEST,
  RESET_PASSWORD_REQUEST,
} from './constants';

export const registration = (payload) => ({
  type: REGISTER_USER_REQUEST,
  payload,
});

export const login = (payload) => ({
  type: LOGIN_USER_REQUEST,
  payload,
});

export const logout = (payload) => ({
  type: LOGOUT_USER,
  payload,
});

export const forgotPass = (payload) => ({
  type: FORGOT_PASS_REQUEST,
  payload,
});

export const changePass = (payload) => ({
  type: CHANGE_PASS_REQUEST,
  payload,
});

export const initialize = () => ({
  type: AUTH_INITIALIZE,
});

export const confirmation = (payload) => ({
  type: CONFIRM_EMAIL_REQUEST,
  payload,
});

export const checkToken = (payload) => ({
  type: IS_TOKEN_VALID_REQUEST,
  payload,
});

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});
