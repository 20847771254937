import { call, put, takeEvery } from 'redux-saga/effects';
import i18n from 'i18n';

import * as ep from 'api/endpoints';
import sendRequest from 'api/axios';
import { getItemFromLocalStorage, setItemToLocalStorage } from 'utils/helpers';
import { SET_ERROR } from 'shared/error/constants';
import { REFRESH_TOKEN_BEGIN } from './constants';

let hasRefreshRequested = false;
let hasRefreshResponsed = false;
let requestList = [];

function* refreshTokenSaga({ payload: { type, data } }) {
  try {
    if (!hasRefreshRequested) {
      hasRefreshRequested = true;

      const token = getItemFromLocalStorage('token');
      const refreshToken = getItemFromLocalStorage('refreshToken');

      const requestData = {
        method: 'POST',
        endpoint: `${ep.ACCOUNT}/tokenRefresh`,
        data: { token, refreshToken },
      };

      const result = yield call(sendRequest, requestData);
      const {
        data: { Token, RefreshToken },
      } = result;

      setItemToLocalStorage('token', Token);
      setItemToLocalStorage('refreshToken', RefreshToken);
      // yield insertTokenInHeader(Token);

      hasRefreshResponsed = true;

      setTimeout(() => {
        hasRefreshRequested = false;
        hasRefreshResponsed = false;
        requestList = [];
      }, 3 * 60 * 1000);
    }

    if (hasRefreshResponsed) {
      yield put({ type, ...data });

      for (const item of requestList) {
        yield put(item);
      }
    } else {
      requestList.push({ type, ...data });
    }
  } catch (e) {
    if (e.response?.status === 401) {
      yield put({
        type: SET_ERROR,
        error: {
          ...e,
          response: {
            ...e.response,
            data: i18n.t('session-expired'),
          },
        },
      });
    }
  }
}

export default function* refreshTokenRequestWatcher() {
  yield takeEvery(REFRESH_TOKEN_BEGIN, refreshTokenSaga);
}
