import * as types from './constants';

const initialState = {
  integrations: false,
  hasRequested: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INTEGRATIONS_SUCCESS:
      return {
        ...state,
        integrations: payload,
      };
    case types.INTEGRATE_SUCCESS:
      return {
        ...state,
        integrations: payload,
        hasRequested: true,
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    default:
      return state;
  }
};
