import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import * as types from './constants';

function* addAd({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ADVERTISEMENT}?name=${payload.name}&duration=${payload.duration}`,
    data: payload.files,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.CREATE_AD_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.CREATE_AD_REQUEST, {
      payload,
    });
  }
}

function* editAd({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.ADVERTISEMENT}?id=${payload.Id}&name=${payload.name}&duration=${payload.duration}`,
    data: payload.files,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.EDIT_AD_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.EDIT_AD_REQUEST, {
      payload,
    });
  }
}

function* getAds() {
  const data = {
    method: 'GET',
    endpoint: ep.ADVERTISEMENT,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.ADS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ADS_REQUEST);
  }
}

function* deleteImg({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.ADVERTISEMENT}/DeleteFile?id=${payload.id}`,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.DELETE_IMAGE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DELETE_IMAGE_REQUEST, {
      payload,
    });
  }
}

function* deleteAd({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.ADVERTISEMENT}/${payload.Id}`,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.DELETE_AD_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DELETE_AD_REQUEST, {
      payload,
    });
  }
}

function* duplicateAd({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ADVERTISEMENT}/Duplicate?id=${payload.Id}`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.DUPLICATE_AD_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DUPLICATE_AD_REQUEST, {
      payload,
    });
  }
}

export default function* adsRequestWatcher() {
  yield takeLatest(types.CREATE_AD_REQUEST, addAd);
  yield takeLatest(types.EDIT_AD_REQUEST, editAd);
  yield takeLatest(types.ADS_REQUEST, getAds);
  yield takeLatest(types.DELETE_IMAGE_REQUEST, deleteImg);
  yield takeLatest(types.DELETE_AD_REQUEST, deleteAd);
  yield takeLatest(types.DUPLICATE_AD_REQUEST, duplicateAd);
}
