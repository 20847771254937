export const QUESTIONS_TYPES_REQUEST = 'QUESTIONS_TYPES_REQUEST';
export const QUESTIONS_TYPES_SUCCESS = 'QUESTIONS_TYPES_SUCCESS';
export const DESIGN_REQUEST = 'DESIGN_REQUEST';
export const DESIGN_SUCCESS = 'DESIGN_SUCCESS';
export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const SURVEY_PAGES_REQUEST = 'SURVEY_PAGES_REQUEST';
export const SURVEY_PAGES_SUCCESS = 'SURVEY_PAGES_SUCCESS';
export const SURVEY_PAGES_REORDER_REQUEST = 'SURVEY_PAGES_REORDER_REQUEST';
export const SURVEY_PAGES_REORDER_SUCCESS = 'SURVEY_PAGES_REORDER_SUCCESS';
export const SET_PAGE_ATTRIBUTE_REQUEST = 'SET_PAGE_ATTRIBUTE_REQUEST';
export const SET_PAGE_ATTRIBUTE_SUCCESS = 'SET_PAGE_ATTRIBUTE_SUCCESS';
export const LABEL_UPDATE_REQUEST = 'LABEL_UPDATE_REQUEST';
export const LABEL_UPDATE_SUCCESS = 'LABEL_UPDATE_SUCCESS';
export const BTN_UPDATE_REQUEST = 'BTN_UPDATE_REQUEST';
export const BTN_UPDATE_SUCCESS = 'BTN_UPDATE_SUCCESS';
export const MULTICOLUMN_UPDATE_REQUEST = 'MULTICOLUMN_UPDATE_REQUEST';
export const MULTICOLUMN_UPDATE_SUCCESS = 'MULTICOLUMN_UPDATE_SUCCESS';
export const MULTI_CHOICE_UPDATE_REQUEST = 'MULTI_CHOICE_UPDATE_REQUEST';
export const MULTI_CHOICE_UPDATE_SUCCESS = 'MULTI_CHOICE_UPDATE_SUCCESS';
export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST';
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS';
export const NEW_OPTION_REQUEST = 'NEW_OPTION_REQUEST';
export const NEW_OPTION_SUCCESS = 'NEW_OPTION_SUCCESS';
export const ANSWER_UPDATE_REQUEST = 'ANSWER_UPDATE_REQUEST';
export const ANSWER_UPDATE_SUCCESS = 'ANSWER_UPDATE_SUCCESS';
export const ANSWER_DELETE_REQUEST = 'ANSWER_DELETE_REQUEST';
export const ANSWER_DELETE_SUCCESS = 'ANSWER_DELETE_SUCCESS';
export const CHANGE_TYPE_REQUEST = 'CHANGE_TYPE_REQUEST';
export const CHANGE_TYPE_SUCCESS = 'CHANGE_TYPE_SUCCESS';
export const DUPLICATE_QUESTION_REQUEST = 'DUPLICATE_QUESTION_REQUEST';
export const DUPLICATE_QUESTION_SUCCESS = 'DUPLICATE_QUESTION_SUCCESS';
export const IMAGE_UPLOAD_REQUEST = 'IMAGE_UPLOAD_REQUEST';
export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const TAB_IMG_REMOVE_REQUEST = 'TAB_IMG_REMOVE_REQUEST';
export const TAB_IMG_REMOVE_SUCCESS = 'TAB_IMG_REMOVE_SUCCESS';
export const PAGE_DESIGN_REQUEST = 'PAGE_DESIGN_REQUEST';
export const PAGE_DESIGN_SUCCESS = 'PAGE_DESIGN_SUCCESS';
export const ELIGABLE_QUESTIONS_REQUEST = 'ELIGABLE_QUESTIONS_REQUEST';
export const ELIGABLE_QUESTIONS_SUCCESS = 'ELIGABLE_QUESTIONS_SUCCESS';
export const SAVE_NEXT_QUESTION_REQUEST = 'SAVE_NEXT_QUESTION_REQUEST';
export const SAVE_NEXT_QUESTION_SUCCESS = 'SAVE_NEXT_QUESTION_SUCCESS';
export const FORM_ITEM_UPDATE_REQUEST = 'FORM_ITEM_UPDATE_REQUEST';
export const FORM_ITEM_UPDATE_SUCCESS = 'FORM_ITEM_UPDATE_SUCCESS';
export const FORM_LABEL_UPDATE_REQUEST = 'FORM_LABEL_UPDATE_REQUEST';
export const FORM_LABEL_UPDATE_SUCCESS = 'FORM_LABEL_UPDATE_SUCCESS';
export const COMPONENT_DETAILS_SUCCESS = 'COMPONENT_DETAILS_SUCCESS';
export const COMPONENT_DETAILS_REQUEST = 'COMPONENT_DETAILS_REQUEST';
