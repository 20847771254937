import React from 'react';
import { Spin } from 'antd';

const Loader = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      background: 'rgb(204 204 204 / 30%)',
      position: 'absolute',
      zIndex: '200000000',
      top: '0',
      left: '0',
      justifyContent: 'space-around',
    }}
  >
    <Spin />
  </div>
);

export default Loader;
