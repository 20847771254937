const theme = {
  darkBlue: '#0C1740',
  darkPurple: '#180C2C',
  lightGray: '#7d80a1',
  lightBlue: '#3bbbd3',
  ghostWhite: '#FBFCFF',
  success: '#3EBC75',
  error: '#FF2A44',
};

export default theme;
