export const SURVEYS_LIST_REQUEST = 'SURVEYS_LIST_REQUEST';
export const SURVEYS_LIST_SUCCESS = 'SURVEYS_LIST_SUCCESS';
export const ADD_SURVEY_REQUEST = 'ADD_SURVEY_REQUEST';
export const ADD_SURVEY_SUCCESS = 'ADD_SURVEY_SUCCESS';
export const ELIGIBLES_LIST_REQUEST = 'ELIGIBLES_LIST_REQUEST';
export const ELIGIBLES_LIST_SUCCESS = 'ELIGIBLES_LIST_SUCCESS';
export const LAUNCH_SURVEY_REQUEST = 'LAUNCH_SURVEY_REQUEST';
export const LAUNCH_SURVEY_SUCCESS = 'LAUNCH_SURVEY_SUCCESS';
export const RENAME_SURVEY_REQUEST = 'RENAME_SURVEY_REQUEST';
export const RENAME_SURVEY_SUCCESS = 'RENAME_SURVEY_SUCCESS';
export const DELETE_SURVEY_REQUEST = 'DELETE_SURVEY_REQUEST';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DUPLICATE_SURVEY_REQUEST = 'DUPLICATE_SURVEY_REQUEST';
export const DUPLICATE_SURVEY_SUCCESS = 'DUPLICATE_SURVEY_SUCCESS';

export const SET_HAS_REQUESTED_FALSE = 'SET_HAS_REQUESTED_FALSE';

export const SUMMARY_REPORT_REQUEST = 'SUMMARY_REPORT_REQUEST';
export const SUMMARY_REPORT_SUCCESS = 'SUMMARY_REPORT_SUCCESS';
export const ENDPOINTS_USED_FOR_SURVEY_REQUEST =
  'ENDPOINTS_USED_FOR_SURVEY_REQUEST';
export const ENDPOINTS_USED_FOR_SURVEY_SUCCESS =
  'ENDPOINTS_USED_FOR_SURVEY_SUCCESS';
export const EXPORT_REPORT_REQUEST = 'EXPORT_REPORT_REQUEST';
