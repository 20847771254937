export const ENDPOINTS_LIST_REQUEST = 'ENDPOINTS_LIST_REQUEST';
export const ENDPOINTS_LIST_SUCCESS = 'ENDPOINTS_LIST_SUCCESS';
export const ADD_ENDPOINT_REQUEST = 'ADD_ENDPOINT_REQUEST';
export const ADD_ENDPOINT_SUCCESS = 'ADD_ENDPOINT_SUCCESS';
export const UPDATE_ENDPOINT_REQUEST = 'UPDATE_ENDPOINT_REQUEST';
export const UPDATE_ENDPOINT_SUCCESS = 'UPDATE_ENDPOINT_SUCCESS';
export const DELETE_ENDPOINT_REQUEST = 'DELETE_ENDPOINT_REQUEST';
export const DELETE_ENDPOINT_SUCCESS = 'DELETE_ENDPOINT_SUCCESS';
export const RESET_PIN_REQUEST = 'RESET_PIN_REQUEST';
export const RESET_PIN_SUCCESS = 'RESET_PIN_SUCCESS';
export const SHORT_ADS_LIST_REQUEST = 'SHORT_ADS_LIST_REQUEST';
export const SHORT_ADS_LIST_SUCCESS = 'SHORT_ADS_LIST_SUCCESS';
export const SET_AD_REQUEST = 'SET_AD_REQUEST';
export const SET_AD_SUCCESS = 'SET_AD_SUCCESS';
export const SET_TRIGGER_REQUEST = 'SET_TRIGGER_REQUEST';
export const SET_TRIGGER_SUCCESS = 'SET_TRIGGER_SUCCESS';
export const BRANCHES_LIST_REQUEST = 'BRANCHES_LIST_REQUEST';
export const BRANCHES_LIST_SUCCESS = 'BRANCHES_LIST_SUCCESS';
export const WORKSTATIONS_LIST_REQUEST = 'WORKSTATIONS_LIST_REQUEST';
export const WORKSTATIONS_LIST_SUCCESS = 'WORKSTATIONS_LIST_SUCCESS';
export const SHORT_SURVEYS_LIST_REQUEST = 'SHORT_SURVEYS_LIST_REQUEST';
export const SHORT_SURVEYS_LIST_SUCCESS = 'SHORT_SURVEYS_LIST_SUCCESS';
export const SET_SURVEY_REQUEST = 'SET_SURVEY_REQUEST';
export const SET_SURVEY_SUCCESS = 'SET_SURVEY_SUCCESS';
export const DOWNLOAD_QR_CODE_REQUEST = 'DOWNLOAD_QR_CODE_REQUEST';
export const DOWNLOAD_QR_CODE_SUCCESS = 'DOWNLOAD_QR_CODE_SUCCESS';
export const GET_QUEUETYPES_REQUEST = 'GET_QUEUETYPES_REQUEST';
export const GET_QUEUETYPES_SUCCESS = 'GET_QUEUETYPES_SUCCESS';
export const GET_ENDPOINT_REQUEST = 'GET_ENDPOINT_REQUEST';
export const GET_ENDPOINT_SUCCESS = 'GET_ENDPOINT_SUCCESS';

export const SET_HAS_REQUESTED_FALSE = 'SET_HAS_REQUESTED_FALSE';
