import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import * as types from './constants';

function* getUsers() {
  const data = {
    method: 'GET',
    endpoint: ep.INVITATIONS,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.USERS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.USERS_LIST_REQUEST);
  }
}

function* addUser({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.INVITATIONS,
    data: { Email: payload.Email, Role: 0 },
  };

  try {
    const response = yield call(sendRequest, data);

    if (payload.type !== 'reinvite') {
      yield put({
        type: types.ADD_USER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    yield handleRequestFail(e, types.ADD_USER_REQUEST, {
      payload,
    });
  }
}

function* deleteUser({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.INVITATIONS}/${payload}`,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.DELETE_USER_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DELETE_USER_REQUEST, {
      payload,
    });
  }
}

export default function* usersRequestWatcher() {
  yield takeLatest(types.USERS_LIST_REQUEST, getUsers);
  yield takeLatest(types.ADD_USER_REQUEST, addUser);
  yield takeLatest(types.DELETE_USER_REQUEST, deleteUser);
}
