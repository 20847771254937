export const URL = process.env.REACT_APP_PUBLIC_URL;
// export const BASE_URL = 'https://survey-dev.earlyone.com/api';
export const SIGNALR_URL = URL;
// export const BASE_URL = 'http://192.168.0.32:60177/api';
export const BASE_URL = `${URL}/api`;
export const BASE_FILE_URL = 'https://e1cdn.blob.core.windows.net/mediafiles';

export const ACCOUNT = '/account';
export const CHANGE_BIO = '/UserProfile';
export const PROJECT = '/Project';
export const INVITATION = '/invitation';
export const INVITATIONS = '/Invitation';
export const ENDPOINTS = '/Endpoint';
export const PROJECT_INTEGRATION = '/ProjectIntegration';
export const SURVEY = '/Survey';
export const SURVEY_PAGES = '/Page';
export const SURVEY_QUESTIONS = '/Question';
export const SURVEY_ANSWERS = '/Answer';
export const ADVERTISEMENT = '/Advertisement';
export const SURVEY_PAGES_DESIGN = '/Designer';
export const SURVEY_PAGES_LOGIC = '/Logic';
export const SURVEY_UI = '/UI';
export const REPORT = '/Report';
export const UPLOAD_IMAGE = '/UploadImage'
