import { all } from 'redux-saga/effects';

import loginRequestWatcher from 'pages/auth/sagas';
import refreshTokenRequestWatcher from 'pages/auth/refreshToken/sagas';
import profileRequestWatcher from 'pages/profile/sagas';
import workspacesRequestWatcher from 'pages/workspaces/sagas';
import usersRequestWatcher from 'pages/project/users/sagas';
import endpointsRequestWatcher from 'pages/project/endpoints/sagas';
import integrationsRequestWatcher from 'pages/project/integrations/sagas';
import surveysRequestWatcher from 'pages/project/surveys/sagas';
import surveyPagesRequestWatcher from 'pages/project/surveys/pages/sagas';
import adsRequestWatcher from 'pages/project/ads/sagas';
import surveyUIRequestWatcher from 'pages/surveyUI/sagas';

export default function* rootSaga() {
  yield all([
    loginRequestWatcher(),
    profileRequestWatcher(),
    refreshTokenRequestWatcher(),
    workspacesRequestWatcher(),
    usersRequestWatcher(),
    endpointsRequestWatcher(),
    integrationsRequestWatcher(),
    surveysRequestWatcher(),
    surveyPagesRequestWatcher(),
    adsRequestWatcher(),
    surveyUIRequestWatcher(),
  ]);
}
