import * as types from './constants';

const initialState = {
  hasRequested: false,
  adsList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADS_LIST_SUCCESS:
      return {
        ...state,
        adsList: payload,
      };
    case types.CREATE_AD_SUCCESS:
    case types.DUPLICATE_AD_SUCCESS:
      return {
        ...state,
        adsList: [payload, ...state.adsList],
        hasRequested: true,
      };
    case types.EDIT_AD_SUCCESS:
      return {
        ...state,
        adsList: state.adsList.map((item) => {
          if (item.Id === payload.Id) {
            return {
              ...payload,
            };
          }

          return item;
        }),
        hasRequested: true,
      };
    case types.DELETE_AD_SUCCESS:
      return {
        ...state,
        hasRequested: true,
        adsList: state.adsList.filter(({ Id }) => Id !== payload.Id),
      };
    case types.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        adsList: state.adsList.map((item) => {
          if (item.Id === payload.ad) {
            return {
              ...item,
              SlidesCount: item.SlidesCount - 1,
              Slides: item.Slides.filter(({ Id }) => Id !== payload.id),
            };
          }

          return item;
        }),
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    default:
      return state;
  }
};
