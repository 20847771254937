import * as types from './constants';

const initialState = {
  endpointsList: [],
  hasRequested: false,
  adsList: [],
  branchesList: [],
  workstationsList: [],
  surveysList: [],
  queueTypes: [],
  endpoint: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ENDPOINTS_LIST_SUCCESS:
      return {
        ...state,
        endpointsList: payload,
      };
    case types.ADD_ENDPOINT_SUCCESS:
      return {
        ...state,
        endpointsList: [payload, ...state.endpointsList],
        hasRequested: true,
      };

    case types.DELETE_ENDPOINT_SUCCESS:
      return {
        ...state,
        endpointsList: state.endpointsList.filter(({ Id }) => Id !== payload),
        hasRequested: true,
      };
    case types.UPDATE_ENDPOINT_SUCCESS:
    case types.SET_AD_SUCCESS:
    case types.SET_TRIGGER_SUCCESS:
    case types.SET_SURVEY_SUCCESS:
      return {
        ...state,
        endpointsList: state.endpointsList.map((item) => {
          if (item.Id === payload.Id) {
            return payload;
          }

          return item;
        }),
        hasRequested: true,
      };
    case types.RESET_PIN_SUCCESS:
      return {
        ...state,
        endpointsList: state.endpointsList.map((item) => {
          if (item.Id === payload.Id) {
            return { ...item, Pin: payload.pin };
          }

          return item;
        }),
      };
    case types.SHORT_ADS_LIST_SUCCESS:
      return {
        ...state,
        adsList: payload,
      };
    case types.BRANCHES_LIST_SUCCESS:
      return {
        ...state,
        branchesList: payload,
      };
    case types.WORKSTATIONS_LIST_SUCCESS:
      return {
        ...state,
        workstationsList: payload,
      };
    case types.SHORT_SURVEYS_LIST_SUCCESS:
      return {
        ...state,
        surveysList: payload,
      };
    case types.SET_HAS_REQUESTED_FALSE:
      return {
        ...state,
        hasRequested: false,
      };
    case types.GET_QUEUETYPES_SUCCESS:
      return {
        ...state,
        queueTypes: payload,
      };
    case types.GET_ENDPOINT_SUCCESS:
      return {
        ...state,
        endpoint: payload,
      };
    default:
      return state;
  }
};
