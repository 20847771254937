import { useLocation, useHistory } from 'react-router-dom';
import { Menu } from 'antd';

import { Design, Logic, Question } from 'assets/svg';
import { StyledSider } from './styles';

const surveyMenu = [
  { name: 'questions', icon: <Question /> },
  { name: 'design', icon: <Design /> },
  { name: 'logic', icon: <Logic /> },
];

export default function QuestionSider() {
  const { pathname } = useLocation();
  const history = useHistory();

  const locationKey = pathname.substring(pathname.lastIndexOf('/') + 1);

  const handleMenuClick = (key) => {
    history.push(`${pathname.substring(0, pathname.lastIndexOf('/'))}/${key}`);
  };

  return (
    <StyledSider flex="0" margin="32px">
      <Menu mode="inline" selectedKeys={[locationKey]}>
        {surveyMenu.map(({ name, icon }) => (
          <Menu.Item
            key={name}
            icon={icon}
            onClick={() => handleMenuClick(name)}
          />
        ))}
      </Menu>
    </StyledSider>
  );
}
