import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import * as types from './constants';

function* getQuestions() {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY_QUESTIONS}/GetTypes`,
  };

  try {
    const response = yield call(sendRequest, data);
    const questions = response.data.sort((a, b) => a.Order - b.Order);

    yield put({
      type: types.QUESTIONS_TYPES_SUCCESS,
      payload: questions,
    });
  } catch (e) {
    yield handleRequestFail(e, types.QUESTIONS_TYPES_REQUEST);
  }
}

function* getComponentDetails() {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY_QUESTIONS}/GetComponentDetails`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.COMPONENT_DETAILS_SUCCESS,
      payload: response.data,
    });
    
  } catch (e) {
    yield handleRequestFail(e, types.COMPONENT_DETAILS_REQUEST);
  }
}

function* getDesign({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY_PAGES_DESIGN}/GetTabContent?surveyId=${payload.surveyId}`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.DESIGN_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DESIGN_REQUEST);
  }
}

function* getPages({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY}/GetSurveyQuestions?surveyId=${
      payload.surveyId
    }&showAnswers=${payload.showAnswers ?? false}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.SURVEY_PAGES_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SURVEY_PAGES_REQUEST);
  }
}

function* setpageAttribute({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: `${ep.SURVEY_QUESTIONS}/Page`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SET_PAGE_ATTRIBUTE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SET_PAGE_ATTRIBUTE_REQUEST, {
      payload,
    });
  }
}

function* createPage({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload.data,
  };

  try {
    const response = yield call(sendRequest, data);
    const page = {
      Key: payload.key,
      Id: response.data,
      QuestionText: payload.data.QuestionText,
    };
    yield put({
      type: types.CREATE_PAGE_SUCCESS,
      payload: page,
    });
  } catch (e) {
    yield handleRequestFail(e, types.CREATE_PAGE_REQUEST, {
      payload,
    });
  }
}

function* getPage({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY_QUESTIONS}/${payload.Id}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.PAGE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.PAGE_REQUEST);
  }
}

function* deletePage({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.SURVEY_QUESTIONS}/${payload.Id}`,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.DELETE_PAGE_SUCCESS,
      payload: payload.Id,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DELETE_PAGE_REQUEST);
  }
}

function* formLabelUpdate({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };
  try {
    yield call(sendRequest, data);

      yield put({
      type: types.FORM_ITEM_UPDATE_SUCCESS,
      payload,
    });
    
  } catch (e) {
    yield handleRequestFail(e, types.FORM_LABEL_UPDATE_REQUEST, {
      payload,
    });
  }
}

function* formUpdate({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.FORM_ITEM_UPDATE_SUCCESS,
      payload,
    });
    
  } catch (e) {
    yield handleRequestFail(e, types.FORM_ITEM_UPDATE_REQUEST, {
      payload,
    });
  }

}

function* btnUpdate({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };

  try {
    yield call(sendRequest, data);
    let updated = {};
    if (typeof payload.Buttons[0].IsEnabled !== 'undefined') {
      updated = { IsEnabled: payload.Buttons[0].IsEnabled };
    }
    if (typeof payload.Buttons[0].Text !== 'undefined') {
      updated = { Text: payload.Buttons[0].Text };
    }
    if (typeof payload.Buttons[0].Url !== 'undefined') {
      updated = { Url: payload.Buttons[0].Url };
    }

    yield put({
      type: types.BTN_UPDATE_SUCCESS,
      payload: updated,
    });
  } catch (e) {
    yield handleRequestFail(e, types.BTN_UPDATE_REQUEST, {
      payload,
    });
  }
}

function* labelsUpdate({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.LABEL_UPDATE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.LABEL_UPDATE_REQUEST, {
      payload,
    });
  }
}

function* updateMultiColumn({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.MULTICOLUMN_UPDATE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.MULTICOLUMN_UPDATE_REQUEST, {
      payload,
    });
  }
}

function* questionUpdate({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.QUESTION_UPDATE_SUCCESS,
      payload: { QuestionText: payload.QuestionText },
    });
  } catch (e) {
    yield handleRequestFail(e, types.QUESTION_UPDATE_REQUEST, {
      payload,
    });
  }
}

function* multiChoiceStatusUpdate({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };
  try {
    yield call(sendRequest, data);
    yield put({
      type: types.MULTI_CHOICE_UPDATE_SUCCESS,
      payload: { IsMultiChoice: payload.IsMultiChoice },
    });
  } catch (e) {
    yield handleRequestFail(e, types.MULTI_CHOICE_UPDATE_REQUEST, {
      payload,
    });
  }
}


function* imageUpload({ payload, callback }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.SURVEY}${ep.UPLOAD_IMAGE}`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);
    callback(response.data)
  } catch (e) {
    yield handleRequestFail(e, types.IMAGE_UPLOAD_REQUEST, {
      payload,
    });
  }
}

function* addNewOption({ payload }) {
  const data = {
    method: 'POST',
    endpoint: ep.SURVEY_ANSWERS,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.NEW_OPTION_SUCCESS,
      payload: { ...payload, Id: response.data },
    });
  } catch (e) {
    yield handleRequestFail(e, types.NEW_OPTION_REQUEST, {
      payload,
    });
  }
}

function* updateAnswer({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.ANSWER_UPDATE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ANSWER_UPDATE_REQUEST, {
      payload,
    });
  }
}

function* deleteAnswer({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.SURVEY_ANSWERS}/${payload.Id}`,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.ANSWER_DELETE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ANSWER_DELETE_REQUEST);
  }
}

function* questionTypeChange({ payload }) {
  const data = {
    method: 'PATCH',
    endpoint: ep.SURVEY_QUESTIONS,
    data: payload.data,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.CHANGE_TYPE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.CHANGE_TYPE_REQUEST, {
      payload,
    });
  }
}

function* duplicateQuestion({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.SURVEY_QUESTIONS}/Duplicate?Id=${payload.Id}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.DUPLICATE_QUESTION_SUCCESS,
      payload: {
        ...payload,
        Id: response.data,
      },
    });
  } catch (e) {
    yield handleRequestFail(e, types.DUPLICATE_QUESTION_REQUEST, {
      payload,
    });
  }
}

function* uploadImg({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.SURVEY_PAGES_DESIGN}/UploadTabImage`,
    data: payload.formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.UPLOAD_IMAGE_SUCCESS,
      payload: { tab: payload.tab, ...response.data },
    });
  } catch (e) {
    yield handleRequestFail(e, types.UPLOAD_IMAGE_REQUEST);
  }
}

function* deleteImg({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.SURVEY_PAGES_DESIGN}/DeleteTabImage?surveyId=${payload.surveyId}&questionTypeId=${payload.questionTypeId}`,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.TAB_IMG_REMOVE_SUCCESS,
      payload: payload.activeTab,
    });
  } catch (e) {
    yield handleRequestFail(e, types.TAB_IMG_REMOVE_REQUEST);
  }
}

function* designSave({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.SURVEY_PAGES_DESIGN}/Save?surveyId=${payload.surveyId}`,
    data: payload.data,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.PAGE_DESIGN_SUCCESS,
      payload: { ...payload.data },
    });
  } catch (e) {
    yield handleRequestFail(e, types.PAGE_DESIGN_REQUEST);
  }
}

function* pageReorder({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.SURVEY_PAGES_LOGIC}/Reorder`,
    data: payload.data,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.SURVEY_PAGES_REORDER_SUCCESS,
      payload: payload.items,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SURVEY_PAGES_REORDER_REQUEST);
  }
}

function* getEligableQuestionsList({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY_PAGES_LOGIC}/GetEligibleQuestions?selectedQuestionId=${payload.selectedQuestionId}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.ELIGABLE_QUESTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ELIGABLE_QUESTIONS_REQUEST);
  }
}

function* saveNextQuestion({ payload, callback }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.SURVEY_PAGES_LOGIC}/SaveNextQuestion`,
    data: payload.data,
  };

  try {
    yield call(sendRequest, data);
    yield put({
      type: types.SAVE_NEXT_QUESTION_SUCCESS,
      payload: payload.item,
    });

    callback();
  } catch (e) {
    yield handleRequestFail(e, types.SAVE_NEXT_QUESTION_REQUEST);
  }
}

export default function* surveyPagesRequestWatcher() {
  yield takeLatest(types.IMAGE_UPLOAD_REQUEST, imageUpload);
  yield takeLatest(types.QUESTIONS_TYPES_REQUEST, getQuestions);
  yield takeLatest(types.COMPONENT_DETAILS_REQUEST, getComponentDetails);
  yield takeLatest(types.DESIGN_REQUEST, getDesign);
  yield takeLatest(types.SURVEY_PAGES_REQUEST, getPages);
  yield takeLatest(types.SET_PAGE_ATTRIBUTE_REQUEST, setpageAttribute);
  yield takeLatest(types.CREATE_PAGE_REQUEST, createPage);
  yield takeLatest(types.PAGE_REQUEST, getPage);
  yield takeLatest(types.DELETE_PAGE_REQUEST, deletePage);
  yield takeLatest(types.BTN_UPDATE_REQUEST, btnUpdate);
  yield takeLatest(types.FORM_ITEM_UPDATE_REQUEST, formUpdate);
  yield takeLatest(types.FORM_LABEL_UPDATE_REQUEST, formLabelUpdate)
  yield takeLatest(types.LABEL_UPDATE_REQUEST, labelsUpdate);
  yield takeLatest(types.MULTICOLUMN_UPDATE_REQUEST, updateMultiColumn);
  yield takeLatest(types.QUESTION_UPDATE_REQUEST, questionUpdate);
  yield takeLatest(types.MULTI_CHOICE_UPDATE_REQUEST, multiChoiceStatusUpdate);
  yield takeLatest(types.NEW_OPTION_REQUEST, addNewOption);
  yield takeLatest(types.ANSWER_UPDATE_REQUEST, updateAnswer);
  yield takeLatest(types.ANSWER_DELETE_REQUEST, deleteAnswer);
  yield takeLatest(types.DUPLICATE_QUESTION_REQUEST, duplicateQuestion);
  yield takeLatest(types.CHANGE_TYPE_REQUEST, questionTypeChange);
  yield takeLatest(types.UPLOAD_IMAGE_REQUEST, uploadImg);
  yield takeLatest(types.TAB_IMG_REMOVE_REQUEST, deleteImg);
  yield takeLatest(types.PAGE_DESIGN_REQUEST, designSave);
  yield takeLatest(types.SURVEY_PAGES_REORDER_REQUEST, pageReorder);
  yield takeLatest(types.ELIGABLE_QUESTIONS_REQUEST, getEligableQuestionsList);
  yield takeLatest(types.SAVE_NEXT_QUESTION_REQUEST, saveNextQuestion);
}
