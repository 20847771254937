import React from 'react';
import Highlighter from 'react-highlight-words';
import i18n from 'i18n';
import { message } from 'antd';
import moment from 'moment';

export const setItemToLocalStorage = (key, value) =>
  localStorage.setItem(key, value);
export const getItemFromLocalStorage = (key) => localStorage.getItem(key);

export const isAuthenticated = () => {
  const token = getItemFromLocalStorage('token');

  return Boolean(token);
};

export const hasSelectedProject = () => {
  const projectId = getItemFromLocalStorage('projectId');

  return Boolean(projectId);
};

export const setupAppInfo = ({ Token, RefreshToken, User }) => {
  setItemToLocalStorage('token', Token);
  setItemToLocalStorage('refreshToken', RefreshToken);
  setItemToLocalStorage('user', JSON.stringify(User));
  // insertTokenInHeader(Token);
};

export const parse = (val) => JSON.parse(val);
export const stringify = (val) => JSON.stringify(val);

export const searchableRow = (val, searchKey) => {
  const higlight = searchKey || '';
  return (
    <Highlighter
      searchWords={[higlight]}
      textToHighlight={val?.toString() || ''}
    />
  );
};

export const downloadResponse = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.append(link);
  link.click();
  link.remove();
};

export const antTableSearch = (searchKey, data, searchingFields, callback) => {
  const shouldFilter = searchKey.length;
  const filterList = shouldFilter ? [] : data;
  if (shouldFilter) {
    const searchValue = searchKey.toLowerCase();
    data.forEach((item) => {
      let canFiltered = false;
      searchingFields.forEach((field) => {
        if (item[field]?.toString().toLowerCase().includes(searchValue)) {
          canFiltered = true;
        }
      });
      if (canFiltered) {
        filterList.push({
          ...item,
          searchKey: searchValue,
        });
      }
    });
  }
  if (callback) {
    callback(filterList);
  }
};

export const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error(i18n.t('img-info'));
  }
  const isLt2M = file.size / 1024 / 1024 < 5;

  if (!isLt2M) {
    message.error(i18n.t('img-size-info'));
  }
  return isJpgOrPng && isLt2M;
};

export const getRangeBetweenDateTimes = (
  start,
  end,
  step,
  unit,
  formatting
) => {
  const range = [];
  const limit = Math.floor(
    moment(end, formatting).diff(moment(start, formatting), unit) / step
  );

  if (limit) {
    for (let i = 0; i <= limit; i += 1) {
      const date = moment(start, formatting)
        .add(i * step, unit)
        .format(formatting);
      range.push(date);
    }
  }

  if (range?.length && !range.includes(end)) {
    range.push(end);
  }

  return range;
};

export const getRange = (start, end, step) => {
  const data = [];

  for (let i = start; i <= end; i += step) {
    data.push(i);
  }

  return data;
};
