import styled from 'styled-components';
import { Layout } from 'antd';

import { LeftSideIcon, Logo } from 'assets/svg';

const { Sider, Content } = Layout;

export const LeftSider = styled(Sider)`
  background: #f3f8ff;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

export const LayoutWrapper = styled(Layout)`
  padding: 42px 32px;
  background: #fbfcff;
  border-top: 1px solid #eaeff9;
`;

export const StyledContent = styled(Content)`
  padding: ${(props) => (props.$isQuestion ? '0px' : '32px')};
  background: ${(props) => (props.$isQuestion ? '#fff' : '#fbfcff')};
  border: 1px solid #eaeff9;
  box-sizing: border-box;
  border-top-left-radius: 30px;
  overflow: auto;
`;
export const StyledLogo = styled(Logo)`
  margin: 40px 40px;
`;
export const StyledBG = styled.div`
  height: 100%;
  background-image: url(${LeftSideIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
