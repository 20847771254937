import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

import { hasSelectedProject, isAuthenticated } from 'utils/helpers';
import Header from 'layout/header';
import SiderMenu from 'layout/sider';
import QuestionMenu from 'layout/questionSider';
import Loader from 'layout/loader';
import { getLoading } from 'store/selectors';
import { LayoutWrapper, StyledContent } from './styles';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const isFetching = useSelector(getLoading);

  const redirectHandler = (opt, url) => {
    setShouldRedirect(opt);
    setRedirectUrl(url);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      if (rest.path !== '/profile' && !hasSelectedProject()) {
        redirectHandler(true, '/workspaces');
      } else if (rest.path === '/project') {
        redirectHandler(
          true,
          hasSelectedProject() ? 'project/surveys' : 'workspaces'
        );
      }
    } else {
      redirectHandler(true, '/login');
    }
  }, [rest]);

  const renderSider = () =>
    rest.path.includes('surveys/pages') ? <QuestionMenu /> : <SiderMenu />;

  return (
    <Route {...rest}>
      {shouldRedirect && <Redirect to={redirectUrl} />}

      <Layout style={{ minHeight: '100vh' }}>
        {isFetching ? <Loader /> : null}
        <Header />
        {rest.path.includes('project') ? (
          <Layout>
            {renderSider()}
            <StyledContent $isQuestion={rest.path.includes('surveys/pages')}>
              <Component />
            </StyledContent>
          </Layout>
        ) : (
          <LayoutWrapper>
            <Component />
          </LayoutWrapper>
        )}
      </Layout>
    </Route>
  );
};

export default PrivateRoute;
