import { call, put, takeLatest, select } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as endpoints from 'api/endpoints';
import { surveyUISelector } from 'store/selectors';
import { store } from 'store/configure-store';
import * as types from './constants';
import { setCookie } from './helper';

function* getSurveyUi({ payload, callback }) {
  const data = {
    method: 'GET',
    endpoint: `${endpoints.SURVEY_UI}/ShowSurvey?qr=${payload.qr}&clientId=${
      payload.clientId
    }${payload.sessionName ? `&sessionName=${payload.sessionName}` : ''}`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.UI_SUCCESS,
      payload: response.data,
    });

    if (payload.callBack && payload.dispatch) {
      payload.dispatch(payload.callBack);
    }

    if (callback) {
      callback();
    }
  } catch (e) {
    setCookie('questionId', '', 0);
    yield handleRequestFail(e, types.UI_REQUEST, {
      payload,
    });
  }
}

function* getQRType({ payload, callback }) {
  const data = {
    method: 'GET',
    endpoint: `${endpoints.SURVEY_UI}/QrType?qr=${payload.qr}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.QRTYPE_SUCCESS,
      payload: response.data || 255,
    });
  } catch (e) {
    yield handleRequestFail(e, types.QRTYPE_REQUEST, {
      payload,
    });
  }
}

function* answerQuestion({ payload, callBack }) {
  const data = {
    method: 'POST',
    endpoint: `${endpoints.SURVEY_QUESTIONS}/Respond`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);
    if (!response.data) {
      callBack();
    } else {
      yield put({
        type: types.ANSWER_QUESTION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (e) {
    if (payload.shouldRefreshSession && e?.response?.status === 403) {
      store.dispatch({
        type: types.REFRESH_SESSION,
        payload: true,
      });
    } else {
      yield handleRequestFail(e, types.ANSWER_QUESTION_REQUEST, {
        payload,
      });
    }
  }
}

function* getQuestion({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${endpoints.SURVEY_QUESTIONS}/${payload.questionId}`,
  };

  try {
    const response = yield call(sendRequest, data);
    const ui = yield select(surveyUISelector);

    yield put({
      type: types.QUESTION_SUCCESS,
      payload:
        ui?.Question?.SurveyId === response.data.SurveyId
          ? response.data
          : ui.Question,
    });
  } catch (e) {
    yield handleRequestFail(e, types.QUESTION_REQUEST, {
      payload,
    });
  }
}

export default function* usersRequestWatcher() {
  yield takeLatest(types.QRTYPE_REQUEST, getQRType);
  yield takeLatest(types.UI_REQUEST, getSurveyUi);
  yield takeLatest(types.ANSWER_QUESTION_REQUEST, answerQuestion);
  yield takeLatest(types.QUESTION_REQUEST, getQuestion);
}
