import styled from 'styled-components';
import { Layout, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';

import { IconMenu } from 'assets/svg';

const { Header } = Layout;
const { Sider } = Layout;
const { Text } = Typography;

export const HeaderWrapper = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #fff;
  max-height: 7vh;
`;

export const FlexJustified = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserInfo = styled(Text)``;

export const ImageDiv = styled.div`
  display: inline-flex;
  background: #e5e5e5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const StyledProjectsListBtn = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: 48px;
  svg {
    margin-left: 12px;
  }
`;

export const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  padding-left: 50px;
  cursor: pointer;
  svg {
    margin-right: 12px;
  }
`;

export const PreviewBtn = styled(Button)`
  display: none;
  margin-right: 24px;
  background: #fff;
  color: ${({ theme }) => theme.darkBlue};

  &:hover,
  &:focus {
    background: #fff;
  }

  svg {
    margin-right: 8px;
  }
`;

export const PublishBtn = styled(Button)`
  display: none;
  margin-right: 24px;
`;

export const StyledSider = styled(Sider)`
  background: white;
  min-width: max-content !important;
  flex: ${(props) => props.flex} !important;

  ${(props) => (props.margin ? 'margin-top : 32px;' : null)}

  li {
    height: 52px !important;
    margin-bottom: 20 !important;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.darkBlue};

    &:hover,
    &:focus,
    &:active,
    &.ant-menu-item-selected {
      background: #fbfcff !important;
    }
  }

  .ant-menu-item::after {
    border-right-width: 0 !important;
  }

  .ant-menu-item-selected {
    border-left: 3px outset #08bbd3;
    font-weight: bold;
    &::after {
      border-right: none !important;
    }

    svg {
      color: #08bbd3;
    }
  }
`;

export const MenuIcon = styled(IconMenu)`
  margin: 46px 24px 40px;
`;
