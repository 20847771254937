export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';

export const FORGOT_PASS_REQUEST = 'FORGOT_PASS_REQUEST';
export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS';

export const CHANGE_PASS_REQUEST = 'CHANGE_PASS_REQUEST';

export const AUTH_INITIALIZE = 'AUTH_INITIALIZE';

export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';

export const IS_TOKEN_VALID_REQUEST = 'IS_TOKEN_VALID_REQUEST';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
