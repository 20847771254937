import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      const errorDetails =
        process.env.NODE_ENV === 'development' && error.toString();
      return (
        <div>
          <h2 className="error">An unexpected error happened.</h2>
          {errorDetails}
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
