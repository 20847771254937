import { call, put, takeLatest } from 'redux-saga/effects';

import sendRequest, { handleRequestFail } from 'api/axios';
import * as ep from 'api/endpoints';
import { downloadResponse } from 'utils/helpers';
import * as types from './constants';

function* getEndpoints() {
  const data = {
    method: 'GET',
    endpoint: ep.ENDPOINTS,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.ENDPOINTS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ENDPOINTS_LIST_REQUEST);
  }
}

function* addEndpoint({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ENDPOINTS}/addOrUpdate`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.ADD_ENDPOINT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.ADD_ENDPOINT_REQUEST, {
      payload,
    });
  }
}

function* updateEndpoint({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.ENDPOINTS}/addOrUpdate`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.UPDATE_ENDPOINT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.UPDATE_ENDPOINT_REQUEST, {
      payload,
    });
  }
}

function* deleteEndpoint({ payload }) {
  const data = {
    method: 'DELETE',
    endpoint: `${ep.ENDPOINTS}/delete?id=${payload}`,
  };

  try {
    yield call(sendRequest, data);

    yield put({
      type: types.DELETE_ENDPOINT_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleRequestFail(e, types.DELETE_ENDPOINT_REQUEST, {
      payload,
    });
  }
}

function* resetPin({ payload }) {
  const data = {
    method: 'POST',
    endpoint: `${ep.ENDPOINTS}/resetPin`,
    data: {
      Id: payload,
    },
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.RESET_PIN_SUCCESS,
      payload: { Id: payload, pin: response.data },
    });
  } catch (e) {
    yield handleRequestFail(e, types.RESET_PIN_REQUEST, {
      payload,
    });
  }
}

function* getAds() {
  const data = {
    method: 'GET',
    endpoint: `${ep.ADVERTISEMENT}?getshort=true`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SHORT_ADS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SHORT_ADS_LIST_REQUEST);
  }
}

function* setAd({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.ENDPOINTS}/setAdvertisement`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SET_AD_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SET_AD_REQUEST, { payload });
  }
}

function* getBranches() {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/getBranches`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.BRANCHES_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.BRANCHES_LIST_REQUEST);
  }
}

function* getWorkstations({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/getWorkstations?branchId=${payload}`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.WORKSTATIONS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.WORKSTATIONS_LIST_REQUEST, { payload });
  }
}

function* setTrigger({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.ENDPOINTS}/setTrigger`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SET_TRIGGER_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SET_TRIGGER_REQUEST, { payload });
  }
}

function* getSurveys() {
  const data = {
    method: 'GET',
    endpoint: `${ep.SURVEY}?getshort=true`,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SHORT_SURVEYS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SHORT_SURVEYS_LIST_REQUEST);
  }
}

function* setSurvey({ payload }) {
  const data = {
    method: 'PUT',
    endpoint: `${ep.ENDPOINTS}/setSurvey`,
    data: payload,
  };

  try {
    const response = yield call(sendRequest, data);

    yield put({
      type: types.SET_SURVEY_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.SET_SURVEY_REQUEST, { payload });
  }
}

function* downloadQrCodeSaga({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/DownloadQrCode?Id=${payload.Id}`,
  };

  try {
    const response = yield call(sendRequest, data);
    downloadResponse(`data:image/png;base64,${response.data}`, 'qr.png');
  } catch (e) {
    yield handleRequestFail(e, types.DOWNLOAD_QR_CODE_REQUEST, { payload });
  }
}

function* getQueueTypesData({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/GetQueueTypes`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.GET_QUEUETYPES_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.GET_QUEUETYPES_REQUEST, { payload });
  }
}

function* getEndpointData({ payload }) {
  const data = {
    method: 'GET',
    endpoint: `${ep.ENDPOINTS}/${payload.id}`,
  };

  try {
    const response = yield call(sendRequest, data);
    yield put({
      type: types.GET_ENDPOINT_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield handleRequestFail(e, types.GET_ENDPOINT_REQUEST, { payload });
  }
}

export default function* endpointsRequestWatcher() {
  yield takeLatest(types.ENDPOINTS_LIST_REQUEST, getEndpoints);
  yield takeLatest(types.ADD_ENDPOINT_REQUEST, addEndpoint);
  yield takeLatest(types.UPDATE_ENDPOINT_REQUEST, updateEndpoint);
  yield takeLatest(types.DELETE_ENDPOINT_REQUEST, deleteEndpoint);
  yield takeLatest(types.RESET_PIN_REQUEST, resetPin);
  yield takeLatest(types.SHORT_ADS_LIST_REQUEST, getAds);
  yield takeLatest(types.SET_AD_REQUEST, setAd);
  yield takeLatest(types.BRANCHES_LIST_REQUEST, getBranches);
  yield takeLatest(types.WORKSTATIONS_LIST_REQUEST, getWorkstations);
  yield takeLatest(types.SET_TRIGGER_REQUEST, setTrigger);
  yield takeLatest(types.SHORT_SURVEYS_LIST_REQUEST, getSurveys);
  yield takeLatest(types.SET_SURVEY_REQUEST, setSurvey);
  yield takeLatest(types.DOWNLOAD_QR_CODE_REQUEST, downloadQrCodeSaga);
  yield takeLatest(types.GET_QUEUETYPES_REQUEST, getQueueTypesData);
  yield takeLatest(types.GET_ENDPOINT_REQUEST, getEndpointData);
}
