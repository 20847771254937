export const getLoading = (state) => state.shared.isFetching;

export const getLoginFailed = (state) => state.user.loginFailed;
export const getForgotPassRequested = (state) => state.user.forgotPassRequested;

export const getIsOpen = (state) => state.error.isOpen;
export const getMessage = (state) => state.error.message;

export const getWSHasRequested = (state) => state.workspaces.hasRequested;
export const getProjectsList = (state) => state.workspaces.projectsList;
export const getInvitationsList = (state) => state.workspaces.invitationsList;

export const getUsersList = (state) => state.users.usersList;
export const getUsersHasRequested = (state) => state.users.hasRequested;

export const getProfileHasRequested = (state) => state.profile.hasRequested;

export const getEndpointsList = (state) => state.endpoints.endpointsList;
export const getEndpointsHasRequested = (state) => state.endpoints.hasRequested;
export const getShortAdsList = (state) => state.endpoints.adsList;
export const getBranchesList = (state) => state.endpoints.branchesList;
export const getWorkstationsList = (state) => state.endpoints.workstationsList;
export const getEndpointsSurveysList = (state) => state.endpoints.surveysList;
export const selectQueueTypes = (state) => state.endpoints.queueTypes;
export const selectEndpoint = (state) => state.endpoints.endpoint;

export const getIntegrations = (state) => state.integrations.integrations;
export const getIntegrationsHasRequested = (state) =>
  state.integrations.hasRequested;

export const getSurveysList = (state) => state.surveys.surveysList;
export const getEligiblesList = (state) => state.surveys.eligiblesList;
export const getSurveysHasRequested = (state) => state.surveys.hasRequested;
export const selectSummaryReport = (state) => state.surveys.summaryReport;
export const selectEndpointsUsedForSurvey = (state) =>
  state.surveys.usedEndpoints;

export const getAdvertisements = (state) => state.ads.adsList;
export const getAdsHasRequested = (state) => state.ads.hasRequested;

export const getProjectId = (state) => state.workspaces.currentProjectId;

export const questionsListSelector = (state) => state.pages.questions;
export const pagesDesignSelector = (state) => state.pages.design;
export const userQuestionsSelector = (state) => state.pages.createdQuestions;
export const editableSelector = (state) => state.pages.editableQuestion;
export const eligablesSelector = (state) => state.pages.eligables;
export const getComponentDetails = (state) => state.pages.componentDetails;

export const surveyUISelector = (state) => state.surveyUI.ui;
export const selectQRType = (state) => state.surveyUI.qrType;
export const surveyUINextQuestionSelector = (state) =>
  state.surveyUI.nextQuestion;
export const surveyUIStatusSelector = (state) => state.surveyUI.status;
export const selectShowInfoMsg = (state) => state.surveyUI.showInfoMsg;
export const selectRefreshSession = (state) => state.surveyUI.refreshSession;
