export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) {
    return match[2];
  }
  return null;
};

export const setCookie = (name, value, seconds) => {
  const expires = `expires=${new Date(
    new Date().getTime() + seconds
  ).toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};
