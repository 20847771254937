import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const ErrorModal = styled(Modal)`
  &&& {
    text-align: center;
  }

  svg {
    margin-bottom: 24px;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 116px;
  margin-top: 24px;
`;
