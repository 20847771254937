export const UI_REQUEST = 'UI_REQUEST';
export const UI_SUCCESS = 'UI_SUCCESS';
export const QRTYPE_SUCCESS = 'QRTYPE_SUCCESS';
export const QRTYPE_REQUEST = 'QRTYPE_REQUEST';

export const ANSWER_QUESTION_REQUEST = 'ANSWER_QUESTION_REQUEST';
export const ANSWER_QUESTION_SUCCESS = 'ANSWER_QUESTION_SUCCESS';
export const QUESTION_REQUEST = 'QUESTION_REQUEST';
export const QUESTION_SUCCESS = 'QUESTION_SUCCESS';

export const START_SURVEY = 'START_SURVEY';
export const END_SURVEY = 'END_SURVEY';
export const TIMEOUT_SURVEY = 'TIMEOUT_SURVEY';
export const SHOW_MSG_INFO = 'SHOW_MSG_INFO';
export const REFRESH_SESSION = "REFRESH_SESSION";
