export const CREATE_AD_REQUEST = 'CREATE_AD_REQUEST';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const EDIT_AD_REQUEST = 'EDIT_AD_REQUEST';
export const EDIT_AD_SUCCESS = 'EDIT_AD_SUCCESS';
export const ADS_REQUEST = 'ADS_REQUEST';
export const ADS_LIST_SUCCESS = 'ADS_LIST_SUCCESS';
export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_AD_REQUEST = 'DELETE_AD_REQUEST';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';
export const DUPLICATE_AD_REQUEST = 'DUPLICATE_AD_REQUEST';
export const DUPLICATE_AD_SUCCESS = 'DUPLICATE_AD_SUCCESS';
export const SET_HAS_REQUESTED_FALSE = 'SET_HAS_REQUESTED_FALSE';
